import { FC, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { SearchQA } from 'shared/dataAttributes'
import shave from 'shave'
import { renderUntil } from 'shared/hocs/render'
import GridItemName from './GridItemName'

const MOBILE_NAME_HEIGHT = 55

const GridName = styled(GridItemName)`
  height: ${MOBILE_NAME_HEIGHT}px;
`

interface IMobileGridNameProps {
  name: string
  className?: string
}

const MobileGridName: FC<IMobileGridNameProps> = ({ name }) => {
  const gridNameEl = useRef(null)
  useEffect(() => {
    shave(gridNameEl.current, MOBILE_NAME_HEIGHT, { spaces: false })
  }, [name])
  return (
    <GridName ref={gridNameEl} data-qa={SearchQA.ProductItemTitle}>
      {name}
    </GridName>
  )
}

MobileGridName.displayName = 'MobileGridName'

export default renderUntil.large(MobileGridName)
