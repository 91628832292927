import Bugsnag, { NotifiableError } from '@bugsnag/js'
import { Group } from '@goat/protos/gen/node/darwin/v1/darwin_pb'
import axios from 'axios'
import { refreshCrsf } from 'shared/utils/requestService'

/**
 * Logs the exposure of the current user to the specified Darwin experiment
 *
 * @param experimentName Name of Darwin experiment
 * @param mainId Primary id sent to Darwin to identify the user
 * @param deviceId device id provided by mParticle
 * @param sessionId session id provided by mParticle
 * @param metaData Optional metadata to append to the log event
 */
export async function logExposure(
  experimentName: string,
  mainId: number | string | null,
  deviceId: string,
  sessionId: string,
  metaData: Record<string, unknown>,
) {
  try {
    const url = `/darwin/log_exposure?experimentName=${experimentName}&mainId=${mainId}${
      deviceId ? `&deviceId=${deviceId}` : ''
    }${sessionId ? `&sessionId=${sessionId}` : ''}`
    const logTimestamp = new Date()
    const response = await axios.post(
      url,
      {
        logTimestamp,
        metaData,
      },
      {
        timeout: 4000,
        headers: {
          'X-CSRF-TOKEN': await refreshCrsf(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    )

    const data = response.data
    return data.group as Group.AsObject
  } catch (error) {
    Bugsnag.notify(error as NotifiableError, (event) => {
      event.context = 'Darwin Service logExposure'
    })
    return Promise.reject()
  }
}
