import { AxiosError } from 'axios'
import isNil from 'lodash/isNil'
import { NextPage } from 'next'
import Head from 'next/head'

import Bugsnag from '@bugsnag/js'
import { useFetchUserSegmentsQuery } from 'api/consumerSegmentAccessApi'
import { EXPERIMENT_NAME } from 'experiments/constants/Experiments'
import {
  useExperimentState,
  useLogExperiment,
  useStartExperiment,
} from 'experiments/hooks/useExperiment'
import Hero from 'homepage/Hero'
import { NavigationSection } from 'homepage/NavigationSection'
import PromotedProductSet from 'homepage/PromotedProductSet'
import SplitScreen from 'homepage/SplitScreen'
import StoresAndHistory from 'homepage/StoresAndHistory'
import { serverSideConfigs, t } from 'localization'
import { serverRequestService } from 'server/services/serverRequestService'
import { logger } from 'server/utils/logger'
import { useShoppingRegionContext } from 'shared/contexts/ShoppingRegionContextProvider'
import CMSSectionType from 'shared/enums/CMSSectionType'
import { FeatureFlag } from 'shared/enums/FeatureFlag'
import { FCGetServerSidePropsContext, getServerPageProps } from 'shared/utils/getServerSideProps'

const TP = 'pages.index'

export type Section = {
  id: number
  type: CMSSectionType
  uuid: string
}
type GetCMSArticle = {
  id: number
  slug: string
  category: string
  sections: Section[]
  uuid: string
}

type HomePageProps = {
  data?: GetCMSArticle
  dataVariant?: GetCMSArticle
  enableExperiments?: boolean
  enableHomeFeedVariantExperiment?: boolean
  error?: any
}

const HomePage: NextPage<HomePageProps> = ({
  data,
  dataVariant,
  enableExperiments,
  enableHomeFeedVariantExperiment,
  error,
}) => {
  const homeFeedExperiment: EXPERIMENT_NAME = 'web_fc_home_feed_variant'
  useStartExperiment(homeFeedExperiment, !enableHomeFeedVariantExperiment)
  useLogExperiment(homeFeedExperiment, !enableHomeFeedVariantExperiment)
  const experimentState = useExperimentState()

  const homeFeedData = (() => {
    if (
      enableExperiments &&
      enableHomeFeedVariantExperiment &&
      experimentState[homeFeedExperiment]?.groupAssigned === 'variant'
    ) {
      return dataVariant
    }
    return data
  })()

  const { country: countryCode } = useShoppingRegionContext()
  const { data: userSegmentData, isLoading: isLoadingUserSegments } = useFetchUserSegmentsQuery({
    countryCode,
  })
  const segmentIds = userSegmentData?.userSegmentsList?.map((segment) => segment.id)

  const authorizedFeedSections =
    homeFeedData?.sections?.filter((section: any) => {
      return !section?.segmentId || segmentIds?.includes(section?.segmentId)
    }) || []

  /**
   * Returns the component for each section type in the feed.
   * If the section type is not supported, it will log a warning and return null.
   *
   * @param article CMS Article that represents the homepage feed
   */
  const getSectionComponent = (sections?: Section[] | undefined) => {
    if (!!sections?.length) {
      return sections.map((section: Section, i: number) => {
        const { id, type, uuid, ...sectionProps } = section
        switch (type) {
          case CMSSectionType.Hero:
          case CMSSectionType.VideoHero:
            return (
              <Hero
                key={`${uuid || id}`}
                draftId={homeFeedData?.uuid || homeFeedData?.id}
                index={i}
                sectionId={uuid || id}
                sectionType={type}
                {...sectionProps}
              />
            )
          case CMSSectionType.NavigationSection:
            return (
              <NavigationSection
                key={`${uuid || id}`}
                last={i + 1 === sections.length}
                {...sectionProps}
              />
            )
          case CMSSectionType.ProductTemplateCarousel:
            return (
              <PromotedProductSet
                draftId={`${homeFeedData?.uuid || homeFeedData?.id || ''}`}
                index={i + 1}
                key={uuid || id}
                sectionId={`${uuid || id}`}
                {...sectionProps}
              />
            )
          case CMSSectionType.TextAndImage:
            return <SplitScreen key={uuid || id} {...sectionProps} />
          case CMSSectionType.CmsSectionType:
            // tslint:disable-next-line no-console
            console.info(`CMS Section type ${type} is not supported (section id=${uuid || id}).`)
            Bugsnag.notify(
              new Error(`CMS Section type ${type} is not supported (section id=${uuid || id}).`),
              (event) => {
                event.addMetadata('CMSsection', section)
                event.context = 'HomePage'
              },
            )
            return null
        }
      })
    }
  }

  return (
    <>
      <Head>
        <title>{t(`${TP}.pageTitle`, 'Sneakers. Here. | Flight Club')}</title>
      </Head>
      {isLoadingUserSegments ? (
        <div style={{ height: '90vh' }} />
      ) : (
        !error && getSectionComponent(authorizedFeedSections)
      )}
      <StoresAndHistory data-testid="storesAndHistory" />
    </>
  )
}

export const getServerSideProps = async (context: FCGetServerSidePropsContext) => {
  const { req, res, locale } = context
  const { featureFlags } = req
  try {
    const tempWebFcHomeFeedVariant =
      featureFlags?.[FeatureFlag.TEMP_WEB_FC_HOME_FEED_VARIANT] ?? false
    const webFcEnableExperiments = featureFlags?.[FeatureFlag.WEB_FC_ENABLE_EXPERIMENTS] ?? false

    const { data } = await serverRequestService(req, res, 'cms').get(
      '/api/v1/cms/cms_articles/fc-home-feed',
    )

    const dataVariant = await (async () => {
      if (webFcEnableExperiments && tempWebFcHomeFeedVariant) {
        const { data: homeFeedVariant } = await serverRequestService(req, res, 'cms').get(
          '/api/v1/cms/cms_articles/fc-home-feed-test-variant-1',
        )
        return homeFeedVariant
      }
      return null
    })()

    return {
      props: {
        ...(await getServerPageProps(context)),
        ...(await serverSideConfigs(res, locale)),
        data,
        dataVariant,
        enableExperiments: webFcEnableExperiments || null,
        enableHomeFeedVariantExperiment: tempWebFcHomeFeedVariant || null,
      },
    }
  } catch (error) {
    const err = error as Error | AxiosError
    logger.error(error, 'Error rendering home page feed')

    if (!isNil(res)) {
      res.statusCode = 500
    }

    return {
      props: {
        ...(await getServerPageProps(context)),
        ...(await serverSideConfigs(res, locale)),
        error: { status: 500, message: err.message },
      },
    }
  }
}

export default HomePage
