import { useContext } from 'react'

import {
  ExperimentDispatchContext,
  ExperimentStateContext,
} from 'experiments/providers/ExperimentProvider'

export { useLogExperiment } from 'experiments/hooks/useLogExperiment'
export { useStartExperiment } from 'experiments/hooks/useStartExperiment'

export const useExperimentState = () => useContext(ExperimentStateContext)
export const useExperimentDispatch = () => useContext(ExperimentDispatchContext)
