import ImageHero, { Picture } from 'homepage/ImageHero'
import VideoHero, { Video } from 'homepage/VideoHero'
import { t } from 'localization'
import { logHomePageHeroTap } from 'mParticle/mParticleService'
import sendTrackingEvent from 'mParticle/sendTrackingEvent'
import Link from 'next/link'
import { HomepageQA } from 'shared/dataAttributes'
import CMSSectionType from 'shared/enums/CMSSectionType'
import { pageWidth, zIndex } from 'shared/lib'
import { colors, mediaQueries } from 'shared/theme'
import styled from 'styled-components'

const TP = 'homepage.hero'

type HeroProps = {
  buttonLink?: string
  buttonLabel?: string
  draftId?: string | number
  index: number
  pictures?: Picture[]
  sectionId?: string | number
  sectionType: CMSSectionType.Hero | CMSSectionType.VideoHero
  segmentId?: string
  title?: string
  titleOrientation?: 'left' | 'center' | 'right'
  videos?: Video[]
}

const Hero = ({
  buttonLink,
  buttonLabel,
  draftId,
  index,
  pictures = [],
  sectionId,
  sectionType,
  segmentId,
  title,
  titleOrientation = 'left',
  videos = [],
}: HeroProps) => {
  const titlePositionMap = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
  }

  const overlayLink = buttonLink || ''

  const handleHeroTap = () => {
    logHomePageHeroTap(
      `/${overlayLink}`,
      'Hero',
      index,
      draftId || '',
      sectionId || '',
      overlayLink,
      segmentId,
    )
    sendTrackingEvent('PDP_TAP', {
      page: window?.location?.href,
      product_template_slug: overlayLink,
      is_filtered: false,
      sort_by: 'relevance',
      event_context: 'discovery_feed',
      search_string: '',
      service_name: 'algolia',
    })
  }

  return (
    <HomeHeroWrapper data-testid="hero" className="transparent-nav-overlay">
      {sectionType === CMSSectionType.VideoHero ? (
        <VideoHero videos={videos} />
      ) : (
        <ImageHero pictures={pictures} title={title} />
      )}
      <LinkOverlay
        data-qa={HomepageQA.LinkOverlay}
        aria-label={t(`${TP}.heroImageAlt`, {
          defaultValue: '{title} Hero Picture',
          title,
        })}
        color="inherit"
        href={`/${overlayLink}`}
        onClick={() => handleHeroTap()}
      />
      <HeroContent titlePosition={titlePositionMap[titleOrientation]}>
        {title && (
          <HeroTextContent>
            <HeroTitle data-qa={HomepageQA.HeroSectionAllStarTitle} data-testid="heroTitle">
              {title}
            </HeroTitle>
            {buttonLink && (
              <Link
                data-qa={HomepageQA.HeroImageLink}
                href={`/${buttonLink}`}
                data-testid="heroLink"
                legacyBehavior
              >
                <StyledLink onClick={() => handleHeroTap()}>
                  {buttonLabel || t(`${TP}.shopNow`, 'Shop Now')}
                </StyledLink>
              </Link>
            )}
          </HeroTextContent>
        )}
      </HeroContent>
    </HomeHeroWrapper>
  )
}

const HomeHeroWrapper = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: -76px;
  height: 90vh;

  &:after {
    display: block;
    content: ' ';
    padding-top: 100%;
  }

  ${mediaQueries.tabletOrLarger} {
    justify-content: left;
  }

  ${mediaQueries.desktop} {
    height: 100vh;
  }
  ${mediaQueries.mobile} {
    height: 85vh;
  }
`

// Overlay for link and a slight tint to make sure white text shows well
const LinkOverlay = styled.a`
  cursor: pointer;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: ${zIndex.justUnderNav};
`
const HeroContent = styled.div<{ titlePosition: string }>`
  ${pageWidth};
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0 auto;
  text-align: ${({ titlePosition }) => (titlePosition === 'center' ? 'center' : 'left')};
  justify-content: ${({ titlePosition }) => titlePosition || 'flex-start'};
  /* override for the hero BUY1-287 */
  ${mediaQueries.desktop} {
    max-width: 1800px;
    padding: 0 50px;
    /* adjust visually for nav bar full bleed height */
    margin-top: -38px;
  }
  ${mediaQueries.tablet} {
    align-items: flex-end;
  }
  ${mediaQueries.mobile} {
    align-items: flex-end;
  }
`
const HeroTextContent = styled.div`
  z-index: ${zIndex.justUnderNav};
  padding: 0 20px;
  margin-bottom: 50px;
  margin-top: 76px; /* compensate for nav header */
  ${mediaQueries.tablet} {
    text-align: center;
  }
  ${mediaQueries.desktop} {
    padding: 0;
    max-width: 600px;
    margin-bottom: 0;
  }
`
const HeroTitle = styled.h1`
  color: ${colors.white};
  font-size: 40px;
  font-weight: 700;
  letter-spacing: -1px;
  line-height: 1.1;
  text-transform: uppercase;
  ${mediaQueries.desktop} {
    font-size: 50px;
  }
  ${mediaQueries.mobile} {
    font-size: 24px;
  }
`
const StyledLink = styled.h2`
  font-size: 16px;
  font-weight: 500;
  color: ${colors.white};
  cursor: pointer;
  margin-top: 20px;
  text-decoration: underline;
  text-transform: capitalize;
  &:hover {
    /* one time use color, merely meant to be a "darker" version of our white for hover */
    color: #c7c7c7;
  }
  ${mediaQueries.mobile} {
    font-size: 14px;
  }
`

export default Hero
