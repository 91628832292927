import Bugsnag, { NotifiableError } from '@bugsnag/js'
import { Group } from '@goat/protos/gen/node/darwin/v1/darwin_pb'
import axios from 'axios'
import { EXPERIMENT_NAME } from 'experiments/constants/Experiments'
import { refreshCrsf } from 'shared/utils/requestService'

/**
 * Assigns a group to the current user for the specified Darwin experiment
 *
 * @param experimentName Name of Darwin experiment
 * @param mainId Primary id sent to Darwin to identify the user
 */
export async function assign(experimentName: EXPERIMENT_NAME, mainId: number | string | null) {
  try {
    const url = `/darwin/assign?experimentName=${experimentName}&mainId=${mainId}`
    const response = await axios.get(url, {
      timeout: 4000,
      headers: {
        'X-CSRF-TOKEN': await refreshCrsf(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })

    const data = response.data
    return data.group as Group.AsObject
  } catch (error) {
    Bugsnag.notify(error as NotifiableError)
    return Promise.reject()
  }
}
