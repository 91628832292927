import { useFeatureFlag } from 'featureFlags/hooks/useFeatureFlag'
import { t } from 'localization'
import { productGridComponentTap } from 'mParticle/mParticleService'
import sendTrackingEvent from 'mParticle/sendTrackingEvent'
import { FC, useEffect, useState } from 'react'
import { Button } from 'shared/components/Button'
import { GridContent } from 'shared/components/ProductGrid/GridContent'
import ProductGrid from 'shared/components/ProductGrid/ProductGrid'
import { FeatureFlag } from 'shared/enums/FeatureFlag'
import { useWindowSize } from 'shared/hooks/useWindowSize'
import { colors, fonts, media, styles } from 'shared/lib'
import IProduct from 'shared/types/IProduct'
import styled from 'styled-components'

const TP = 'homepage.promotedProductSet'

const MIN_PRODUCT_TEMPLATES = 4
const MAX_PRODUCT_TEMPLATES = 8

type PromotedProductSet = {
  buttonLink?: string
  draftId: string
  index?: number
  productTemplates: IProduct[]
  sectionId: string
  text: string
  segmentId: string
}

const PromotedProductSet: FC<PromotedProductSet> = ({
  buttonLink,
  draftId,
  index,
  productTemplates = [],
  sectionId,
  segmentId,
  text,
}) => {
  if (productTemplates.length < MIN_PRODUCT_TEMPLATES) {
    return null
  }
  const isCollectionLinkStyleUpdateEnabled = useFeatureFlag(
    FeatureFlag.TEMP_WEB_FC_ENABLE_NEW_COLLECTION_LINK_STYLE,
  )

  const [products, setProducts] = useState(productTemplates)

  const dataTrackingEventButton = () => {
    productGridComponentTap(`/${buttonLink}`)
    sendTrackingEvent('HOMEPAGE_MODULE_TAP', {
      url: `https://www.flightclub.com/${buttonLink}`,
      module_name: text,
      module_position: index,
      draft_id: draftId,
      section_id: sectionId,
      segment_id: segmentId,
    })
  }

  const dataTrackingEventItem = (itemPosition: number, slug: string) => {
    sendTrackingEvent('HOMEPAGE_MODULE_ITEM_TAP', {
      url: `https://www.flightclub.com/${slug}`,
      module_name: text,
      module_position: index,
      item_position: itemPosition,
      product_template_slug: slug,
      draft_id: draftId,
      section_id: sectionId,
      segment_id: segmentId,
    })
  }

  useEffect(() => {
    if (
      productTemplates.length > MIN_PRODUCT_TEMPLATES &&
      productTemplates.length < MAX_PRODUCT_TEMPLATES
    ) {
      setProducts(productTemplates.slice(0, MIN_PRODUCT_TEMPLATES))
    }
    if (productTemplates.length > MAX_PRODUCT_TEMPLATES) {
      setProducts(productTemplates.slice(0, MAX_PRODUCT_TEMPLATES))
    }
  }, [productTemplates])

  const { isMobile } = useWindowSize()

  const MobileCollectionLink = () => {
    return (
      isCollectionLinkStyleUpdateEnabled &&
      buttonLink && (
        <MobileButtonWrapper>
          <Button
            buttonType="secondary"
            dataTrackingEvent={dataTrackingEventButton}
            href={`/${buttonLink}`}
            $fill
          >
            {t(`${TP}.shopText`, { defaultValue: 'Shop {text}', text })}
          </Button>
        </MobileButtonWrapper>
      )
    )
  }

  const DesktopCollectionLink = () => {
    return (
      (isCollectionLinkStyleUpdateEnabled && buttonLink && (
        <Button
          buttonType="link"
          dataTrackingEvent={dataTrackingEventButton}
          href={`/${buttonLink}`}
        >
          <CollectionLinkWrapper>
            {t(`${TP}.shopText`, { defaultValue: 'Shop {text}', text })}
          </CollectionLinkWrapper>
        </Button>
      )) ||
      null
    )
  }

  return (
    <Container>
      <GridContent>
        <CollectionTitleWrapper>
          <Title>{text}</Title>
          {!isMobile && <DesktopCollectionLink />}
        </CollectionTitleWrapper>
        <ProductGrid
          dataTrackingEvent={dataTrackingEventItem}
          ensureNoWidow
          hidePrices
          products={products.map((product) => {
            return {
              ...product,
              pictureUrl: product.smallUrl,
            }
          })}
        />
        {isMobile && <MobileCollectionLink />}
        {!isCollectionLinkStyleUpdateEnabled && buttonLink && (
          <ButtonWrapper>
            <ButtonLink
              buttonType="primary1"
              component="a"
              dataTrackingEvent={dataTrackingEventButton}
              href={`/${buttonLink}`}
            >
              {t(`${TP}.shopText`, { defaultValue: 'Shop {text}', text })}
            </ButtonLink>
          </ButtonWrapper>
        )}
      </GridContent>
    </Container>
  )
}

const Title = styled.h2`
  ${fonts.HEADER_1}
  display: inline-block;
  margin: 0 0;
`

const Container = styled.section`
  padding: 60px ${styles.mobilePageLayoutPadding} 0 ${styles.mobilePageLayoutPadding};
  display: flex;
  flex-direction: column;
  ${media.large`
    padding: 100px 0 0 0;
  `};
`

const ButtonWrapper = styled.div`
  display: block;
  margin: 40px 0 0;
  text-align: center;
`

const MobileButtonWrapper = styled.div`
  display: block;
  margin: 40px 0 0;
  text-align: center;
`

const CollectionLinkWrapper = styled.div`
  color: ${colors.FC2_GREY};
  font-size: 14px;
  line-height: 17px;
  text-decoration-color: ${colors.FC2_GREY};
`

const CollectionTitleWrapper = styled.div`
  align-items: baseline;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 40px;
`

const ButtonLink = styled(Button)`
  height: fit-content;
  line-height: 16px;
  padding: 16px;
  width: fit-content;
`

export default PromotedProductSet
