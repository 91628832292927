import { createContext, FC, useContext, ReactNode } from 'react'

const PageContext = createContext({
  value: '',
})

export const usePageContext = () => useContext(PageContext)

interface IPageContextProvider {
  children: ReactNode
  value: string
}

const PageContextProvider: FC<IPageContextProvider> = ({ children, value }) => {
  const state = { value }
  return <PageContext.Provider value={state}>{children}</PageContext.Provider>
}

export default PageContextProvider
