import Link from 'next/link'
import styled from 'styled-components'

import Image from 'shared/components/Image'
import { fonts, media, pageWidth, styles } from 'shared/lib'
import { sizes } from 'shared/lib/media'

type NavCardProps = {
  href: string
  imageUrl: string
  label: string
}

type Subsection = {
  text: string
  url: string
  mediaAsset: {
    url: string
    type: string
  }
}

export type NavigationSectionProps = {
  id: string
  key: string
  last: boolean
  subsections: Subsection[]
  text: string
  title: string
}

/**
 * Gets the href for the navigation link in navigation cards
 * @param navURL - the url of the navigation in a CMS article
 */
const getNavLinkHref = (navURL: string) => {
  const navLinkBegin = /.*navigation-links|.*navigation/g
  const originalLink = navURL
  return originalLink.includes('https://')
    ? originalLink.slice(originalLink.indexOf('https://'))
    : originalLink.replace(navLinkBegin, '')
}

/**
 * Navigation card component
 */
const NavCard = ({ label, href, imageUrl }: NavCardProps) => {
  const navLinkHref = getNavLinkHref(href)
  return (
    <Card>
      <Link href={navLinkHref} data-qa="navigationSectionImage">
        <ImageContainer>
          <Image
            alt={label}
            data-testid="image"
            sizes={`(min-width: ${sizes.medium}px) 500px, 100vw`}
            src={imageUrl}
            width={240}
            height={303}
          />
        </ImageContainer>
      </Link>
      <Link href={navLinkHref} legacyBehavior>
        <LabelLink>{label}</LabelLink>
      </Link>
    </Card>
  )
}

/**
 * Navigation section component for the homepage
 */
export const NavigationSection = ({ last, title, subsections }: NavigationSectionProps) => {
  return (
    <Container $last={last}>
      <Title>{title}</Title>
      <RowContent $numCards={subsections.length}>
        {subsections.map((subsection) => (
          <NavCard
            href={subsection.url}
            imageUrl={subsection.mediaAsset.url}
            label={subsection.text}
            key={subsection.url}
          />
        ))}
      </RowContent>
    </Container>
  )
}

export default NavigationSection

const Container = styled.section<{ $last: boolean }>`
  padding: ${({ $last }) =>
    $last
      ? `60px ${styles.mobilePageLayoutPadding} 60px ${styles.mobilePageLayoutPadding}`
      : `60px ${styles.mobilePageLayoutPadding} 0 ${styles.mobilePageLayoutPadding}`};
  display: flex;
  flex-direction: column;
  margin: auto;
  ${pageWidth}
  ${media.large`
    padding: ${({ $last }) => ($last ? '150px 0' : '150px 0 0 0')};
  `};
`

const RowContent = styled.div<{ $numCards: number }>`
  display: ${({ $numCards }) => ($numCards % 2 === 0 ? 'grid' : 'flex')};
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  ${({ $numCards }) =>
    $numCards % 2 === 0 ? media.medium`grid-gap: 80px;` : media.medium`grid-gap: 20px;`};

  ${media.medium`
    grid-template-columns: 1fr 1fr 1fr 1fr;
  `}
`

const Title = styled.h2`
  ${fonts.HEADER_1}
  margin: 0 0 40px;
`
const Card = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100%;

  a {
    width: 100%;
  }

  ${media.medium`
    margin: 0px;
  `}
`
const LabelLink = styled.a`
  ${fonts.LINK}
  cursor: pointer;
  line-height: 18px;
  padding-top: 10px;
  text-align: center;
  text-decoration: none;
  ${media.large`
    padding-top: 36px;
  `};
`

const ImageContainer = styled.div`
  position: relative;
  height: 208px;
  ${media.large`
    height: 170px;
  `}
  ${media.extraLarge`
    height: 303px;
  `}
`
