import { t } from 'localization'
import Image from 'shared/components/Image'
import { HomepageQA } from 'shared/dataAttributes'
import { useWindowSize } from 'shared/hooks/useWindowSize'
import styled from 'styled-components'

export type Picture = {
  thumbUrl: string
  smallUrl: string
  mediumUrl: string
  largeUrl: string
}

type ImageHeroProps = {
  pictures: Picture[]
  title?: string
}

const TP = 'homepage.ImageHero'

const ImageHero = ({ pictures, title }: ImageHeroProps) => {
  const { isMobile } = useWindowSize()
  return (
    <>
      {pictures?.length === 1 && (
        <ImageWrapper>
          <StyledImage
            data-testid="heroImage"
            src={pictures[0].largeUrl}
            alt={t(`${TP}.heroImageAlt`, {
              defaultValue: '{title} Hero Picture',
              title,
            })}
          />
        </ImageWrapper>
      )}
      {pictures?.length > 1 && (
        <>
          {isMobile ? (
            <MobileImageWrapper>
              <MobileStyledImage
                data-qa={HomepageQA.HeroImageMobile}
                data-testid="heroImageMobile"
                src={pictures[1].largeUrl}
                alt={t(`${TP}.heroImageMobileAlt`, {
                  defaultValue: '{title} Hero Picture Mobile',
                  title,
                })}
                sizes="100vw"
                fill
              />
            </MobileImageWrapper>
          ) : (
            <DesktopImageWrapper>
              <DesktopStyledImage
                data-qa={HomepageQA.HeroImageDesktop}
                data-testid="heroImageDesktop"
                src={pictures[0].largeUrl}
                alt={t(`${TP}.heroImageDesktopAlt`, {
                  defaultValue: '{title} Hero Picture Desktop',
                  title,
                })}
                sizes="100vw"
                fill
              />
            </DesktopImageWrapper>
          )}
        </>
      )}
    </>
  )
}

const ImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`
// the &&& trick to is to assure extended styles override
// their base component styles
// https://github.com/styled-components/styled-components/issues/382
const StyledImage = styled(Image)`
  &&& {
    height: 100%;
    object-fit: cover;
  }
`
const DesktopImageWrapper = styled.div`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`
// the &&& trick to is to assure extended styles override
// their base component styles
// https://github.com/styled-components/styled-components/issues/382
const DesktopStyledImage = styled(Image)`
  display: block;
  &&& {
    height: 100%;
    object-fit: cover;
  }
`
const MobileImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`
// the &&& trick to is to assure extended styles override
// their base component styles
// https://github.com/styled-components/styled-components/issues/382
const MobileStyledImage = styled(Image)`
  &&& {
    height: 100%;
    object-fit: cover !important;
  }
`

export default ImageHero
