import { t } from 'localization'
import { HomepageQA } from 'shared/dataAttributes'
import { useWindowSize } from 'shared/hooks/useWindowSize'
import { mediaQueries } from 'shared/theme'
import styled, { css } from 'styled-components'

type VideoPosterImg = {
  aspect?: number
  containsList?: []
  id?: number
  imageFileName?: string
  largeUrl?: string
  mediumUrl?: string
  productMarkers?: []
  relatedList?: []
  smallUrl?: string
  thumbUrl?: string
  type?: 'picture'
  url?: string
}

export type Video = {
  aspect?: number
  containsList?: []
  containsListButtonLink?: string
  containsListCollectionType?: string
  containsListId?: number
  id?: number
  imageFileName?: string
  poster?: VideoPosterImg
  productMarkers?: [{ productTemplateSlug?: string }]
  relatedList?: []
  subtitle?: string
  title?: string
  type?: string
  url: string
}

type VideoHeroProps = {
  videos: Video[]
}

const TP = 'homepage.VideoHero'

const VideoHero = ({ videos }: VideoHeroProps) => {
  const [defaultVideo, mobileVideo] = videos
  const { url: urlDefault, poster: posterDefault } = defaultVideo
  const urlMobile = mobileVideo?.url
  const posterMobile = mobileVideo?.poster
  const { isMobile } = useWindowSize()

  const url = isMobile && urlMobile ? urlMobile : urlDefault
  const poster = isMobile && posterMobile ? posterMobile : posterDefault

  return (
    <VideoHeroWrapper $isMobile={isMobile}>
      <video
        autoPlay
        data-qa={HomepageQA.VideoHero}
        disablePictureInPicture
        loop
        muted
        playsInline
        poster={poster?.largeUrl}
        preload="auto"
        src={`${url}#t=0.5`}
      >
        <source src={url} type="video/mp4" />
        <source src={url} type="video/ogg" />
        {t(`${TP}.heroVideoNotSupported`, 'Your browser does not support the video.')}
      </video>
    </VideoHeroWrapper>
  )
}

const VideoHeroWrapper = styled.div<{ $isMobile?: boolean }>`
  background: black;
  height: 100%;
  overflow: hidden;
  width: 100%;

  video {
    ${({ $isMobile }) =>
      $isMobile
        ? css`
            height: auto;
            margin: 50vh 0 0 50vw;
            transform: translate(-50%, -70%);
          `
        : css`
            height: 100%;
            margin-left: 50vw;
            transform: translate(-50%);

            ${mediaQueries.minAR8_5} {
              height: auto;
              width: 100%;
            }
          `}
  }
`

export default VideoHero
