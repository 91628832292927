import { apiSlice } from 'api/apiSlice'
import { FetchUserSegmentsParams, FetchUserSegmentsResponse } from 'shared/types/Segment'

// Create a new api by injecting endpoints into the root api slice
// Ref: https://redux-toolkit.js.org/rtk-query/usage/code-splitting
export const consumerSegmentAccessApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    fetchUserSegments: builder.query<FetchUserSegmentsResponse, FetchUserSegmentsParams>({
      query: ({ countryCode }) => ({
        url: `/web-api/v1/consumer-segment-access/get-user-segments/${countryCode}`,
        method: 'GET',
      }),
    }),
  }),
})

export const { useFetchUserSegmentsQuery } = consumerSegmentAccessApi
