import { FC, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { SearchQA } from 'shared/dataAttributes'
import shave from 'shave'
import { renderStart } from 'shared/hocs/render'
import GridItemName from './GridItemName'

const ITEM_NAME_HEIGHT = 36

const GridName = styled(GridItemName)`
  height: ${ITEM_NAME_HEIGHT}px;
`
interface IDesktopGridNameProps {
  name: string
  className?: string
}

const DesktopGridName: FC<IDesktopGridNameProps> = ({ name }) => {
  const gridNameEl = useRef(null)
  useEffect(() => {
    shave(gridNameEl.current, ITEM_NAME_HEIGHT, { spaces: false })
  }, [name])
  return (
    <GridName ref={gridNameEl} data-qa={SearchQA.ProductItemTitle}>
      {name}
    </GridName>
  )
}

DesktopGridName.displayName = 'DesktopGridName'

export default renderStart.large(DesktopGridName)
