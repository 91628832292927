import styled from 'styled-components'
import { colors, fonts } from 'shared/lib'

const GridItemName = styled.h2`
  ${fonts.SUBTITLE_2}
  hyphens: auto;
  line-height: 18px;
  margin: 0px;
  overflow-wrap: break-word;
  overflow: hidden;
  word-wrap: break-word;
  &:hover {
    color: ${colors.FC2_BLACK};
  }
`

export default GridItemName
