import sendTrackingEvent from 'mParticle/sendTrackingEvent'
import { useRouter } from 'next/router'
import { FC } from 'react'
import { AlgoliaIndex } from 'search/enums/search'
import Image from 'shared/components/Image'
import { LinkCTA } from 'shared/components/Links'
import { usePageContext } from 'shared/contexts/PageContextProvider'
import { SearchQA } from 'shared/dataAttributes'
import SpecificPageTrackingEvents from 'shared/enums/SpecificPageTrackingEvents'
import { colors, fonts, media } from 'shared/lib'
import styled from 'styled-components'
import DesktopGridName from './DesktopGridName'
import MobileGridName from './MobileGridName'

interface IGridItemProps {
  brand: string
  className?: string
  currency: string
  dataTrackingEvent?: (...args: any) => void
  hidePrice?: boolean
  imageUrl: string
  index?: number
  name: string
  price?: string
  productUrl: string
}

const GridItem: FC<IGridItemProps> = ({
  brand,
  className,
  dataTrackingEvent,
  hidePrice,
  imageUrl,
  index,
  name,
  price,
  productUrl,
}) => {
  const pageContext = usePageContext()
  const router = useRouter()

  const handlePdpAnalytics = () => {
    const urlParams = router.query
    const filterParams = [
      'brand',
      'model',
      'color',
      'condition',
      'size_men',
      'size_women',
      'size_youth',
      'price_cents_usd_max',
      'price_cents_usd_min',
    ]

    const isFiltered = filterParams.filter(filter => filter in urlParams).length > 0
    const isSearch = 'query' in urlParams
    const eventContext = (() => {
      if (isSearch) return 'search_results'
      if (pageContext.value === SpecificPageTrackingEvents.ProductTemplatePage) return 'related_pdp'
      if (pageContext.value === SpecificPageTrackingEvents.FilteredGridPage) return 'plp'
      if (pageContext.value === SpecificPageTrackingEvents.CollectionPage) return 'collection'
      return 'discovery_feed'
    })()

    const selectedSort = (() => {
      if (urlParams.sortBy === AlgoliaIndex.Relevance) return 'relevance'
      if (urlParams.sortBy === AlgoliaIndex.PriceLowHigh) return 'price_low'
      if (urlParams.sortBy === AlgoliaIndex.PriceHighLow) return 'price_high'
      if (urlParams.sortBy === AlgoliaIndex.Newest) return 'newest'
      if (urlParams.sortBy === AlgoliaIndex.Trending) return 'trending'
      return 'relevance'
    })()

    sendTrackingEvent('PDP_TAP', {
      page: window?.location?.href,
      product_template_slug: productUrl,
      is_filtered: isFiltered,
      sort_by: selectedSort,
      event_context: eventContext,
      search_string: urlParams.query?.query?.query || urlParams.query || '',
      service_name: 'algolia',
    })
  }

  const handleDataTrackingEvent = () => {
    if (dataTrackingEvent) {
      return dataTrackingEvent(index + 1, productUrl)
    }
    return handlePdpAnalytics()
  }

  return (
    <LinkCTA
      color="inherit"
      dataTrackingEvent={handleDataTrackingEvent}
      href={`/${productUrl}`}
      qaAttr={SearchQA.ProductItemsUrl}
    >
      <Wrapper className={className} data-qa={SearchQA.ProductItems}>
        <GridImageContainer>
          <Image
            alt={name}
            src={imageUrl}
            data-testid="gridItemImage"
            data-qa={SearchQA.ProductItemThumbnail}
            sizes="(max-width: 768px) 50vw, (max-width: 1200px) 33vw, 25vw"
            width={750}
            height={195}
          />
        </GridImageContainer>

        <GridInfo>
          <InfoWrapper>
            <GridBrand data-qa={SearchQA.ProductItemBrandName} data-testid="gridItemBrand">
              {brand}
            </GridBrand>
            <MobileGridName name={name} />
            <DesktopGridName name={name} />
          </InfoWrapper>
          {!hidePrice && (
            <GridPrice data-qa={SearchQA.ProductItemPrice} data-testid="gridItemPrice">
              {price}
            </GridPrice>
          )}
        </GridInfo>
      </Wrapper>
    </LinkCTA>
  )
}

GridItem.displayName = 'GridItem'

const Wrapper = styled.figure`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin: 0;
  background-color: ${colors.FC2_WHITE};
`

const GridImageContainer = styled.div`
  position: relative;
  width: calc(100% - 10px);
  margin: 20px 10px 30px;
  flex-grow: 1;
  object-fit: contain;
  min-height: 100px;
  height: 114px;
  ${media.large`
    min-height: 150px;
    height: 195px;
  `}
  
`

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`
const GridInfo = styled.figcaption`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 20px 35px;
`

const GridBrand = styled.div`
  ${fonts.SUBTITLE_3};
  color: ${colors.FC2_GREY};
  margin-bottom: 5px;
`

const GridPrice = styled.div`
  margin-top: 10px;
  ${fonts.SUBTITLE_1}
`

export default GridItem
