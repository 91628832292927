import { t } from 'localization'
import { get } from 'lodash/fp'
import { FC } from 'react'
import { Button } from 'shared/components/Button'
import Image from 'shared/components/Image'
import { LinkCTA } from 'shared/components/Links'
import { colors, fonts, media, pageWidth } from 'shared/lib'
import styled from 'styled-components'

const TP = 'homepage.splitScreen'

type CMSPicture = {
  id: number
  thumbUrl: string
  smallUrl: string
  mediumUrl: string
  largeUrl: string
}
export type SplitScreenProps = {
  title: string
  text: string
  buttonLink?: string
  buttonLabel?: string
  pictureOrientation: string
  pictures: CMSPicture[]
}

const SplitScreen: FC<SplitScreenProps> = ({
  title,
  text,
  buttonLink,
  buttonLabel,
  pictureOrientation = 'left',
  pictures,
}) => {
  const pictureSource = !!(pictures && pictures.length)
    ? get('mediumUrl')(pictures[0])
    : fallBackSplitScreenImage

  return (
    <SplitScreenWrapper pictureOrientation={pictureOrientation}>
      <ImageLink
        href={`/${buttonLink}`}
        ariaLabel={t(`${TP}.imageAriaLabel`, {
          defaultValue: 'Featured Collection Image {title}',
          title,
        })}
      >
        <StyledImage
          data-testid="splitScreenImage"
          src={pictureSource}
          alt={t(`${TP}.imageAlt`, {
            defaultValue: 'Featured Collection Image {title}',
            title,
          })}
          width={495}
          height={477}
        />
      </ImageLink>
      <TextScreen>
        <TextWrapper pictureOrientation={pictureOrientation} data-testid="splitScreenText">
          <Title>{title}</Title>
          <Text>{text}</Text>
        </TextWrapper>
        {buttonLink && (
          <ButtonWrapper
            color="white"
            href={`/${buttonLink}`}
            data-testid="splitScreenButton"
            pictureOrientation={pictureOrientation}
          >
            <ButtonLink>{buttonLabel || t(`${TP}.shopAll`, 'Shop All')}</ButtonLink>
          </ButtonWrapper>
        )}
      </TextScreen>
    </SplitScreenWrapper>
  )
}

export default SplitScreen

const SplitScreenWrapper = styled.section<{ pictureOrientation: string }>`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  background-color: ${colors.FC2_WHITE};

  ${media.large`
    margin-bottom: 0;
  `}
  ${media.large`
    flex-direction: ${({ pictureOrientation }) =>
      pictureOrientation === 'left' ? 'row' : 'row-reverse'};
  `};
`
const ImageLink = styled(LinkCTA)`
  display: flex;
  max-height: 203px;

  ${media.extraSmall`
    max-height: 238px;
  `}

  ${media.small`
    max-height: 432px;
  `}

  ${media.large`
    flex: 1;
  `}
`
const TextScreen = styled.div`
  ${pageWidth}
  position: relative;
  margin: 0 auto;
  width: 100%;
  padding: 30px 20px 60px;

  ${media.small`
    display: flex;
    padding: 40px 10px;
    justify-content: space-between;
  `}

  ${media.large`
    flex-direction: column;
    justify-content: center;
    padding: 0;
    flex: 1;
  `}
`
const TextWrapper = styled.div<{ pictureOrientation: string }>`
  ${media.large`
    width: 470px;
    ${({ pictureOrientation }) =>
      pictureOrientation === 'right'
        ? 'padding-left: 10px; padding-right: 100px; margin: 0 0 0 auto;'
        : 'padding-left: 100px; padding-right: 10px; margin: 0 auto 0 0;'}
  `}
  ${media.extraLarge`
    width: 600px;
    padding-left: ${({ pictureOrientation }) => (pictureOrientation === 'left' ? '200px' : '10px')};
  `}
`
const ButtonWrapper = styled(LinkCTA) <{ pictureOrientation: string }>`
  ${media.large`
    width: 470px;
       ${({ pictureOrientation }) =>
      pictureOrientation === 'right'
        ? 'padding-left: 10px; margin: 0 0 0 auto;'
        : 'padding-left: 100px; margin: 0 auto 0 0;'}
  `}
  ${media.extraLarge`
    width: 600px;
    padding-left: ${({ pictureOrientation }) => (pictureOrientation === 'left' ? '200px' : '10px')};
  `}
`
const Title = styled.h2`
  ${fonts.HEADER_2}
  font-weight: normal;
  margin-top: 0;

  ${media.small`
    ${fonts.HEADER_1}
    margin: 0 0 15px;
  `}
`
const StyledImage = styled(Image)`
  width: 100%;
  object-fit: cover;
`
const Text = styled.p`
  ${fonts.BODY_TEXT}
  margin-bottom: 40px;
`
const ButtonLink = styled(Button)`
  width: 100%;

  ${media.small`
    width: 250px;
  `}
  ${media.large`
    width: 230px;
  `}
`
export const fallBackSplitScreenImage = '/static/hard-coded-cms/staff_picks.png'
